import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { showConnectWalletModal } from "../context/wallet/actions";
import { WalletContext } from "../context/wallet/context";

interface Props {
  children: React.ReactNode;
  onClick?(): void;
  disabled?: boolean;
  invokeOnClickAfterAuthenticated?: boolean;
}

const RequireConnectWalletButton: React.FC<Props> = ({
  children,
  onClick,
  disabled = false,
  invokeOnClickAfterAuthenticated = false,
}) => {
  const { isAuthenticated, account } = useMoralis();
  const wallet = useContext(WalletContext);
  const [shouldInvokeOnClick, setShouldInvokeOnClick] = useState(false);

  const onPress = useCallback(() => {
    if (!isAuthenticated || !account) {
      wallet.dispatch(showConnectWalletModal(true));

      setShouldInvokeOnClick(true);
    } else {
      onClick?.();
    }
  }, [isAuthenticated, account, onClick, wallet]);

  useEffect(() => {
    if (
      shouldInvokeOnClick &&
      isAuthenticated &&
      account &&
      invokeOnClickAfterAuthenticated
    ) {
      onPress();

      setShouldInvokeOnClick(false);
    }
  }, [
    shouldInvokeOnClick,
    isAuthenticated,
    account,
    onPress,
    invokeOnClickAfterAuthenticated,
  ]);

  return (
    <div
      className={"cursor-pointer"}
      style={{ pointerEvents: disabled ? "none" : "auto" }}
      onClick={onPress}
    >
      {children}
    </div>
  );
};

export { RequireConnectWalletButton };
