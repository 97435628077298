import { NFTSvgTheme } from "./types";

const themes: {
  [id: string]: NFTSvgTheme;
} = {
  paper: {
    id: "paper",
    background: "#fbf5ec",
    cardBackground: "#000000",
    text: "#ffffff",
    qrBackground: "#000000",
    qr: "#ffffff",
    mode: "light",
  },
  white: {
    id: "white",
    background: "#ffffff",
    cardBackground: "#000000",
    text: "#ffffff",
    qrBackground: "#000000",
    qr: "#ffffff",
    mode: "light",
  },
  black: {
    id: "black",
    background: "#000000",
    cardBackground: "#ffffff",
    text: "#000000",
    qrBackground: "#ffffff",
    qr: "#000000",
    mode: "dark",
  },
};

export const getTheme = (id: "paper" | "white" | "black"): NFTSvgTheme => {
  return themes[id] ?? themes["paper"];
};

export const getThemes = () => themes;
