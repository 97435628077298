import { MediaQueryState } from "./state";
import { ActionType, MediaQueryActions } from "./actions";

export function mediaQueryReducer(
  state: MediaQueryState,
  action: MediaQueryActions
): MediaQueryState {
  switch (action.type) {
    case ActionType.SetMediaQueryMatched: {
      return {
        ...state,
        tablet: action.tablet,
      };
    }

    default:
      return state;
  }
}
