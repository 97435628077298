export const connectors = [
  {
    title: "Metamask",
    icon: "/WalletIcons/metamaskWallet.png",
    connectorId: "injected",
    priority: 1,
    availableOnMobile: true,
  },
  {
    title: "Mobile Wallet",
    icon: "/WalletIcons/wallet-connect.svg",
    connectorId: "walletconnect",
    priority: 2,
    availableOnMobile: true,
  },
  {
    title: "Trust Wallet",
    icon: "/WalletIcons/TrustWallet.png",
    connectorId: "injected",
    priority: 3,
    availableOnMobile: false,
  },
  {
    title: "MathWallet",
    icon: "/WalletIcons/MathWallet.svg",
    connectorId: "injected",
    priority: 999,
    availableOnMobile: false,
  },
  {
    title: "TokenPocket",
    icon: "/WalletIcons/TokenPocket.svg",
    connectorId: "injected",
    priority: 999,
    availableOnMobile: false,
  },
  {
    title: "SafePal",
    icon: "/WalletIcons/SafePal.svg",
    connectorId: "injected",
    priority: 999,
    availableOnMobile: false,
  },
  {
    title: "Coin98",
    icon: "/WalletIcons/Coin98.png",
    connectorId: "injected",
    priority: 999,
    availableOnMobile: false,
  },
];
