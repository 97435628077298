import React, { useReducer } from "react";
import { initialState, NotificationState } from "./state";
import { NotificationActions } from "./actions";
import { notificationReducer } from "./reducer";

export interface NotificationContextValue {
  state: NotificationState;
  dispatch: React.Dispatch<NotificationActions>;
}

export const NotificationContext =
  React.createContext<NotificationContextValue>({
    state: initialState,
    dispatch: () => undefined,
  });

export const NotificationContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(notificationReducer, initialState);

  return (
    <NotificationContext.Provider value={{ state, dispatch }}>
      {children}
    </NotificationContext.Provider>
  );
};
