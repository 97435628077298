import { toChecksumAddress } from "./formatters";
import { Coord, IpfsUrlFn } from "./types";
export const delay = (milliseconds: number) =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));

const uriEncodedName = (name: string) => name.replace("#", "%23");
export const generateTwitterUrl = ({
  name,
  tokenId,
  isOwner,
}: {
  name: string;
  tokenId: number;
  isOwner: boolean;
}) => {
  const url = `${location.origin}/token/${tokenId}`;
  const related = "0xsoji";
  const hashes = ["xNFT", "RightClickSaveArt"];
  const text = isOwner
    ? `In true web3 fashion, expect derivatives. I now own the rights to ${uriEncodedName(
        name
      )}.`
    : `In true web3 fashion, expect derivatives. RCSA is the first co-created conceptual art experiment that tokenises the RightClickSave meme in its rightful form (an idea with participation).`;
  return `https://twitter.com/intent/tweet?url=${url}&text=${text}&related=${related}&hashtags=${hashes.join(
    ","
  )}`;
};

export const getRCSATitle = ({
  underlyingNftTokenId,
  underlyingNftName,
  underlyingNftSymbol,
  withPrefixRCS = true,
  withPrefixX = true,
}: {
  underlyingNftTokenId: string;
  underlyingNftName: string;
  underlyingNftSymbol: string;
  withPrefixRCS?: boolean;
  withPrefixX?: boolean;
}) => {
  let text = underlyingNftName.includes("#")
    ? underlyingNftName
    : underlyingNftName.replace(/\s/g, "");
  if (underlyingNftSymbol && underlyingNftTokenId.length < 7) {
    text = `${underlyingNftSymbol} #${underlyingNftTokenId}`;
  }
  return withPrefixRCS
    ? `RightClickSave ${text.toUpperCase()}`
    : `${withPrefixX ? "x" : ""}${text.toUpperCase()}`;
};

export const isValidAddress = (address: string): boolean => {
  if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
    // check if it has the basic requirements of an address
    return false;
  } else if (
    /^(0x)?[0-9a-f]{40}$/.test(address) ||
    /^(0x)?[0-9A-F]{40}$/.test(address)
  ) {
    // If it's all small caps or all caps, return true
    return true;
  }

  // check checksum
  return address == toChecksumAddress(address);
};

export const ipfsUrlDefault = (cid: string, path = ""): string =>
  `https://ipfs.io/ipfs/${cid}${path}`;

const IPFS_PROTOCOL_RE = /^ipfs:\/\/(?:ipfs\/)?([^/]+)(\/.+)?(\/)?$/;
const IPFS_HASH_RE = /^Qm[1-9A-HJ-NP-Za-km-z]{44}$/;

export const ipfsUrlFromString = (
  ipfsString: string,
  ipfsUrl: IpfsUrlFn
): string => {
  // ipfs:// URI
  const ipfsProtocolMatch = IPFS_PROTOCOL_RE.exec(ipfsString);
  if (ipfsProtocolMatch) {
    const [, cid, path = ""] = ipfsProtocolMatch;
    return ipfsUrl(cid, path);
  }

  // standalone cid, probably
  if (IPFS_HASH_RE.test(ipfsString)) {
    return ipfsUrl(ipfsString);
  }

  // maybe URL
  return ipfsString;
};

export const normalizeImageUrl = (url: string): string =>
  ipfsUrlFromString(url, ipfsUrlDefault);

export const friendlyErrorMessages = (error: any, defaultMessage: string) => {
  switch (typeof error) {
    case "object":
      return error?.reason ?? error.message ?? defaultMessage;
    case "string":
      return error.toString() ?? defaultMessage;
    default:
      return defaultMessage;
  }
};

// [min, max]
const rand = (...coord: Coord): number =>
  Math.floor(Math.random() * (coord[1] - coord[0] + 1) + coord[0]);

export const getRandomCoord = async (x: Coord, y: Coord): Promise<Coord> => [
  rand(...x),
  rand(...y),
];

export function promiseAny<T>(promises: Promise<T>[]): Promise<T> {
  return reversePromise(
    Promise.all([...promises].map(reversePromise))
  ) as Promise<T>;
}
export function reversePromise(promise: Promise<unknown>): Promise<unknown> {
  return new Promise((resolve, reject) => {
    Promise.resolve(promise).then(reject, resolve);
  });
}
