import React, { useReducer } from "react";
import { WalletState, initialState } from "./state";
import { WalletActions } from "./actions";
import { walletReducer } from "./reducer";

export const WalletContext = React.createContext<{
  state: WalletState;
  dispatch: React.Dispatch<WalletActions>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const WalletContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(walletReducer, initialState);

  return (
    <WalletContext.Provider value={{ state, dispatch }}>
      {children}
    </WalletContext.Provider>
  );
};
