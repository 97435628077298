import React from "react";

export enum ActionType {
  ShowConfirmationModal,
  CloseConfirmationModal,
}

export interface ShowConfirmationModal {
  type: ActionType.ShowConfirmationModal;
  payload: React.ReactElement | undefined;
}

export interface CloseConfirmationModal {
  type: ActionType.CloseConfirmationModal;
}

export type ConfirmationModalActions =
  | ShowConfirmationModal
  | CloseConfirmationModal;

export const showConfirmationModal = (
  children: React.ReactElement
): ShowConfirmationModal => ({
  type: ActionType.ShowConfirmationModal,
  payload: children,
});

export const closeConfirmationModal = (): CloseConfirmationModal => ({
  type: ActionType.CloseConfirmationModal,
});
