import React, { useEffect, useState } from "react";
import Image from "next/image";
import Spacer from "./Spacer";
import styles from "styles/Cart.module.scss";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {}

const MintingState: React.FC<Props> = ({}) => {
  const { t } = useTranslation();

  const [dots, setDots] = useState("");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (dots.length >= 3) {
        setDots("");
      } else {
        setDots(dots + ".");
      }
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [dots]);

  return (
    <div
      className={clsx("column ai-center jc-center h-full", styles.emptyCart)}
    >
      <Image
        priority
        src={"/warning.svg"}
        width={40}
        height={40}
        alt={"warning"}
        layout={"fixed"}
      />
      <Spacer height={24} />
      <strong className={styles.text}>
        {t("cart.pleaseWait")}
        {dots}
      </strong>
      <div className={styles.text}>{t("cart.mintingText")}</div>
    </div>
  );
};

export { MintingState };
