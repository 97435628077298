import { animated, easings, Spring } from "react-spring";
import React, { useEffect, useRef } from "react";

interface FadeInProps {
  children: React.ReactNode;
}
export const FadeIn: React.FC<FadeInProps> = ({ children }) => {
  return (
    <Spring
      from={{ opacity: 0 }}
      to={{ opacity: 1 }}
      config={{ duration: 450, easing: easings.easeOutQuart }}
    >
      {(animatedStyles) => {
        return (
          <animated.div
            style={animatedStyles}
            className={"w-full column ai-center"}
          >
            {children}
          </animated.div>
        );
      }}
    </Spring>
  );
};

interface AbsoluteSlideUpProps {
  children: React.ReactNode;
}
export const AbsoluteSlideUp: React.FC<AbsoluteSlideUpProps> = ({
  children,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <Spring
      from={{
        bottom: -(
          contentRef.current?.clientHeight ??
          global.window?.innerHeight ??
          0
        ),
      }}
      to={{ bottom: 0 }}
      config={{ duration: 450, easing: easings.easeOutQuart }}
    >
      {(animatedStyles) => {
        return (
          <animated.div
            style={{ position: "absolute", ...animatedStyles }}
            className={"w-full column ai-center"}
            ref={contentRef}
          >
            {children}
          </animated.div>
        );
      }}
    </Spring>
  );
};
