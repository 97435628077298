import React, { useReducer } from "react";
import { ConfirmationModalState, initialState } from "./state";
import { ConfirmationModalActions } from "./actions";
import { confirmationModalReducer } from "./reducer";

export const ConfirmationModalContext = React.createContext<{
  state: ConfirmationModalState;
  dispatch: React.Dispatch<ConfirmationModalActions>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const ConfirmationModalContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(confirmationModalReducer, initialState);

  return (
    <ConfirmationModalContext.Provider value={{ state, dispatch }}>
      {children}
    </ConfirmationModalContext.Provider>
  );
};
