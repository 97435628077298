import React, { CSSProperties, useState } from "react";
import { isBase64Data } from "../utils/imageKit";
import { ErrorBoundary } from "@sentry/nextjs";
// @ts-ignore
import { IKImage } from "imagekitio-react";

interface Props {
  imgId?: string;
  imageDataOrUrl: string;
  optimisedSrc?: string;
  title: string;
  onMediaLoaded?(): void;
  size: number;
  style: CSSProperties;
  lazyLoadImage?: boolean;
}

const NFTImage: React.FC<Props> = ({
  imgId,
  title,
  imageDataOrUrl,
  optimisedSrc,
  size,
  onMediaLoaded,
  style,
  lazyLoadImage,
}) => {
  const [shouldFallback, setShouldFallback] = useState(false);
  const imgEl = (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      id={imgId}
      src={imageDataOrUrl}
      alt={title}
      width={size}
      height={size}
      style={style}
      onLoad={onMediaLoaded}
    />
  );

  if (isBase64Data(imageDataOrUrl) || shouldFallback) {
    return imgEl;
  }

  return (
    <ErrorBoundary fallback={imgEl}>
      <IKImage
        id={imgId}
        src={optimisedSrc || imageDataOrUrl}
        alt={title}
        width={size}
        height={size}
        style={style}
        onLoad={onMediaLoaded}
        onError={() => setShouldFallback(true)}
        {...(lazyLoadImage && {
          loading: "lazy",
          lqip: { active: true, quality: 10 },
        })}
      />
    </ErrorBoundary>
  );
};

export { NFTImage };
