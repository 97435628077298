import React, { useEffect, useReducer } from "react";
import { CartState, ExpandingStatus, initialState } from "./state";
import {
  CartActions,
  setExpandingStatus,
  updateCartItem,
  updateCartItems,
} from "./actions";
import { cartReducer } from "./reducer";
import { getNFTMetadata, getSavedCartIds } from "../../utils/cart";
import { useRouter } from "next/router";

export const CartContext = React.createContext<{
  state: CartState;
  dispatch: React.Dispatch<CartActions>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const CartContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  const router = useRouter();

  useEffect(() => {
    (async () => {
      const ids = getSavedCartIds();
      dispatch(
        setExpandingStatus(
          ids.length > 0 ? ExpandingStatus.small : ExpandingStatus.close
        )
      );

      dispatch(updateCartItems(ids.map((id) => ({ id }))));
      await Promise.all(
        ids.map(async (id): Promise<void> => {
          const [contractAddress, tokenId] = id.split("_");
          try {
            const data = await getNFTMetadata({ contractAddress, tokenId });
            if (data) {
              dispatch(updateCartItem({ id, nft: data }));
            }
          } catch (e) {
            console.error("load saved cart", e);
          }
        })
      );
    })();
  }, []);

  useEffect(() => {
    if (!router.isReady) return;

    if (
      ["/terms-of-service", "/protocol-disclaimer"].includes(router.pathname)
    ) {
      dispatch(setExpandingStatus(ExpandingStatus.close));
    }
  }, [router]);

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};
