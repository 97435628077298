import React, { useContext, useEffect } from "react";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
  Timestamp,
} from "@firebase/firestore";
import { db } from "../utils/firebase";
import { NotificationContext } from "../context/notifications/context";
import {
  NotificationType,
  showNotification,
} from "../context/notifications/actions";
import { getEllipsisTxt } from "../utils/formatters";
import { RCSANFT } from "../utils/types";
import { getRCSATitle } from "../utils/utils";

const SocialNotifications: React.FC = () => {
  const notification = useContext(NotificationContext);
  useEffect(() => {
    const q = query(
      collection(db, "social-notifications"),
      where("createdAt", ">=", Timestamp.now()),
      orderBy("createdAt", "desc"),
      limit(1)
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot
        .docChanges()
        .filter((docChange) => docChange.type === "added")
        .forEach((docChange) => {
          const data = docChange.doc.data().data as RCSANFT;
          notification.dispatch(
            showNotification({
              data,
              type: NotificationType.socialMinted,
              text: `${getEllipsisTxt(
                data.creatorAddress
              )} just minted <strong>${getRCSATitle({
                underlyingNftName: data.underlyingNftName,
                underlyingNftSymbol: data.underlyingNftSymbol,
                underlyingNftTokenId: data.underlyingNftTokenId,
                withPrefixRCS: false,
              })}</strong>`,
            })
          );
        });
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export { SocialNotifications };
