export enum ActionType {
  SetMediaQueryMatched,
}

export interface SetMediaQueryMatched {
  type: ActionType.SetMediaQueryMatched;
  tablet: boolean;
}

export type MediaQueryActions = SetMediaQueryMatched;

export const setMediaQueryMatched = (tablet: boolean): SetMediaQueryMatched => {
  return {
    type: ActionType.SetMediaQueryMatched,
    tablet: tablet,
  };
};
