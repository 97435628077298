import { ConfirmationModalState } from "./state";
import { ActionType, ConfirmationModalActions } from "./actions";

export function confirmationModalReducer(
  state: ConfirmationModalState,
  action: ConfirmationModalActions
): ConfirmationModalState {
  switch (action.type) {
    case ActionType.ShowConfirmationModal: {
      return {
        ...state,
        children: action.payload,
      };
    }

    case ActionType.CloseConfirmationModal: {
      return {
        ...state,
        children: undefined,
      };
    }

    default:
      return state;
  }
}
