import { NotificationState } from "./state";
import { ActionType, NotificationActions } from "./actions";

export function notificationReducer(
  state: NotificationState,
  action: NotificationActions
): NotificationState {
  switch (action.type) {
    case ActionType.ShowNotification: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
