import React, { useEffect, useReducer } from "react";
import { MediaQueryState, initialState } from "./state";
import { MediaQueryActions, setMediaQueryMatched } from "./actions";
import { mediaQueryReducer } from "./reducer";
import { tabletQuery } from "../../utils/breakpoints";

export const MediaQueryContext = React.createContext<{
  state: MediaQueryState;
  dispatch: React.Dispatch<MediaQueryActions>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const MediaQueryContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(mediaQueryReducer, initialState);

  useEffect(() => {
    const mql = global.window.matchMedia(tabletQuery);
    const handler = () => {
      dispatch(setMediaQueryMatched(mql.matches));
    };

    handler();

    try {
      mql.addEventListener("change", handler);
    } catch (e1) {
      mql.addListener(handler);
    }

    return (): void => {
      try {
        mql.removeEventListener("change", handler);
      } catch (e1) {
        mql.removeListener(handler);
      }
    };
  }, []);

  return (
    <MediaQueryContext.Provider value={{ state, dispatch }}>
      {children}
    </MediaQueryContext.Provider>
  );
};
