import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Image from "next/image";
import Spacer from "./Spacer";
import { NFTMediaItem } from "../utils/types";
import { extractFramesFromVideo } from "../utils/video";
import { getRCSATitle } from "../utils/utils";
import { getTheme } from "../utils/theme";
// @ts-ignore
import { IKImage } from "imagekitio-react";
import { getQRCodeUrl } from "../utils/qrCode";

import { NFTImage } from "./NFTImage";
import { isBase64Data } from "../utils/imageKit";

const originalCardWidth = 366;

interface Props {
  item: NFTMediaItem;
  width: number;
  onMediaLoaded?(): void;
  onQRLoaded?(): void;
  useImageIfVideo?: boolean;
  imgId?: string;
  hideRCSImage?: boolean;
  lazyLoadImage?: boolean;
  forceToUseQRURL?: boolean;
  useOptimisedSrc?: boolean;
}

const NFTMedia: React.FC<Props> = ({
  item,
  width,
  onMediaLoaded,
  onQRLoaded,
  useImageIfVideo = false,
  imgId,
  forceToUseQRURL = false,
  hideRCSImage = false,
  lazyLoadImage = true,
  useOptimisedSrc = true,
}) => {
  const mediaDataOrUrl = item.mediaUrl;
  const optimisedSrc = item.mediaUrlOptimised;
  const title = item.name;
  const theme = getTheme(item.theme);
  const mimeType = item.mimeType ?? "";

  const ratio = width / originalCardWidth;

  const isVideo = mimeType.startsWith("video/") ?? false;

  const mediaStyle: CSSProperties = useMemo(
    () => ({
      borderTopLeftRadius: 10 * ratio,
      borderTopRightRadius: 10 * ratio,
      objectFit: "cover",
    }),
    [ratio]
  );
  const mediaSize = useMemo(() => 366 * ratio, [ratio]);

  const [imageDataOrUrl, setImageDataOrUrl] = useState(
    useImageIfVideo && isVideo ? "" : mediaDataOrUrl
  );

  useEffect(() => {
    if (!imageDataOrUrl && useImageIfVideo && isVideo) {
      extractFramesFromVideo(mediaDataOrUrl, { at: 0 }).then(
        (base64ImageData) => {
          setImageDataOrUrl(base64ImageData);
        }
      );
    }
  }, [imageDataOrUrl, mediaDataOrUrl, isVideo, useImageIfVideo]);

  const qrCodeSize = useMemo(() => 90 * ratio, [ratio]);
  const qrCodeImageDataUrl = useMemo(
    () =>
      forceToUseQRURL
        ? getQRCodeUrl({ tokenId: item.tokenId, theme })
        : item.qr || getQRCodeUrl({ tokenId: item.tokenId, theme }),
    [forceToUseQRURL, item.tokenId, item.qr, theme]
  );

  const renderMedia = useCallback(() => {
    if (!useImageIfVideo && isVideo) {
      return (
        <video
          autoPlay
          muted
          loop
          width={mediaSize}
          height={mediaSize}
          style={mediaStyle}
          onLoadedData={onMediaLoaded}
        >
          <source src={mediaDataOrUrl} type={mimeType} />
        </video>
      );
    }

    return (
      <NFTImage
        lazyLoadImage={lazyLoadImage}
        title={title}
        imageDataOrUrl={imageDataOrUrl}
        optimisedSrc={useOptimisedSrc ? optimisedSrc : undefined}
        imgId={imgId}
        size={mediaSize}
        style={mediaStyle}
        onMediaLoaded={onMediaLoaded}
      />
    );
  }, [
    useOptimisedSrc,
    useImageIfVideo,
    isVideo,
    lazyLoadImage,
    title,
    imageDataOrUrl,
    optimisedSrc,
    imgId,
    mediaSize,
    mediaStyle,
    onMediaLoaded,
    mediaDataOrUrl,
    mimeType,
  ]);

  if (!imageDataOrUrl) return null;

  return (
    <div
      className={"column relative"}
      style={{
        background: theme.cardBackground,
        borderRadius: 10 * ratio,
        width: 366 * ratio,
        height: 486.64 * ratio,
        flexShrink: 0,
      }}
    >
      {renderMedia()}
      {!hideRCSImage && (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          src={"/rcsa.png"}
          width={173 * ratio}
          height={218 * ratio}
          alt={"rcs image"}
          style={{
            position: "absolute",
            right: "-20%",
            top: "20%",
          }}
        />
      )}
      <div
        className={"row ai-center jc-center"}
        style={{
          padding: `0 ${12 * ratio}px`,
          flex: 1,
        }}
      >
        {isBase64Data(qrCodeImageDataUrl) ? (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            src={qrCodeImageDataUrl}
            width={qrCodeSize}
            height={qrCodeSize}
            alt={"QRCode"}
            onLoad={onQRLoaded}
          />
        ) : (
          <IKImage
            path={qrCodeImageDataUrl}
            width={qrCodeSize}
            height={qrCodeSize}
            alt={"QRCode"}
            {...(lazyLoadImage && {
              loading: "lazy",
              lqip: { active: true, quality: 60 },
            })}
            onLoad={onQRLoaded}
          />
        )}
        <div style={{ marginLeft: 12 * ratio }}>
          <div
            style={{
              color: theme.text,
              marginBottom: 8 * ratio,
              fontSize: 14 * ratio,
              fontWeight: 600,
              lineHeight: `${14 * ratio}px`,
            }}
          >
            RIGHT CLICK SAVE ART #{item.tokenId?.toString(16).toUpperCase()}
          </div>
          <div className={"row ai-center jc-start"}>
            <div
              style={{
                color: theme.text,
                fontSize: 14 * ratio,
                fontWeight: 400,
                lineHeight: `${14 * ratio}px`,
              }}
            >
              {getRCSATitle({
                underlyingNftName: item.name,
                underlyingNftTokenId: item.underlyingNftTokenId,
                underlyingNftSymbol: item.underlyingNftSymbol,
                withPrefixRCS: false,
              })}
            </div>
            <Spacer flex={0.15} />
            {item.verified && (
              <Image
                priority
                src={"/nft-card/verified.svg"}
                width={20 * ratio}
                height={20 * ratio}
                alt={"verified"}
                layout={"fixed"}
              />
            )}
          </div>
        </div>
        <Spacer flex={1} />
      </div>
    </div>
  );
};

export { NFTMedia };
