import axios from "axios";
import { useMoralis, useWeb3Contract } from "react-moralis";
import {
  CartItem,
  MintParams,
  MintArgs,
  CreateWalletResponse,
  ThemeIndex,
} from "./types";
import abi from "../fixtures/abi";
import { MINT_FEE } from "./constants";

const indexOfTheme = {
  paper: 0,
  white: 1,
  black: 2,
};

const batchMint = ({
  cartItems,
  minter,
  to,
  royaltyAddress,
  signature,
  fee,
}: MintParams): MintArgs => ({
  functionName: "mintBatch",
  account: minter,
  params: {
    to,
    ids: cartItems.map((c) => c.nft?.tokenId) as number[],
    themes: cartItems.map(
      (c) => indexOfTheme[c.nft?.theme || "paper"]
    ) as ThemeIndex[],
    royaltyAddress,
    signature,
  },
  msgValue: cartItems.length * parseInt(fee, 10),
});

const singleMint = ({
  cartItems,
  minter,
  to,
  royaltyAddress,
  signature,
  fee,
}: MintParams): MintArgs => ({
  functionName: "mint",
  account: minter,
  params: {
    to,
    id: cartItems[0]?.nft?.tokenId as number,
    theme: indexOfTheme[cartItems[0]?.nft?.theme || "paper"] as ThemeIndex,
    royaltyAddress,
    signature,
  },
  msgValue: parseInt(fee, 10),
});

export const useMint = (
  cartItems: CartItem[],
  mintTo: string,
  {
    onSuccess,
    onError,
  }: {
    onSuccess(tx: any): Promise<void>;
    onError(_error: Error): void;
  }
) => {
  const { account } = useMoralis();
  const { runContractFunction } = useWeb3Contract({});

  const handleMinting = async () => {
    let wallet: CreateWalletResponse | null = null;
    try {
      const { data } = await axios.post<CreateWalletResponse>(
        "/api/createWallet",
        {
          ids:
            cartItems.length > 1
              ? cartItems.map((c) => c.nft?.tokenId)
              : cartItems[0]?.nft?.tokenId,
          address: mintTo,
        }
      );
      wallet = data;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        onError(
          new Error((e as any)?.response?.data?.message ?? "Unknown error")
        );
      }
    }

    if (wallet == null) return;

    const mintParams: MintParams = {
      cartItems,
      minter: account!,
      to: mintTo,
      royaltyAddress: wallet.royaltyAddress,
      signature: wallet.signature,
      fee: MINT_FEE,
    };

    await runContractFunction({
      // @ts-ignore
      params: {
        abi: abi,
        contractAddress: process.env.NEXT_PUBLIC_CONTRACT_ADDRESS,
        ...(mintParams.cartItems.length > 1
          ? batchMint(mintParams)
          : singleMint(mintParams)),
      },
      onSuccess,
      onError,
    });
  };

  return {
    mint: handleMinting,
  };
};
