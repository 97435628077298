import { NFTMediaItem, RCSANFT } from "./types";
import { svgToDataUri } from "./formatters";
import { getTheme } from "./theme";
import { getQRCodeSVGString } from "./qrCode";
import { getRandomCoord, getRCSATitle } from "./utils";
import { nullAddress } from "./constants";

export const generateRcsNftImage = async (data: RCSANFT): Promise<string> => {
  const theme = getTheme(data.theme);
  const qrCodeSVGString = await getQRCodeSVGString({
    tokenId: data.tokenId,
    qr: theme.qr,
    qrBackground: theme.qrBackground,
  });
  // Temporary for old docs without xPos and yPos
  const coords = !!data.xPos
    ? [data.xPos, data.yPos]
    : (await getRandomCoord([37200, 39200], [10500, 12500])).map(
        (c) => c / 100
      );
  // const coords = [data.xPos, data.yPos];

  const title = getRCSATitle({
    underlyingNftName: data.underlyingNftName,
    underlyingNftTokenId: data.underlyingNftTokenId,
    underlyingNftSymbol: data.underlyingNftSymbol,
    withPrefixRCS: false,
  });

  const qrCodeSVG = qrCodeSVGString
    .replace('<?xml version="1.0" standalone="no"?>', "")
    .replace("<svg", '<svg x="134" y="545"');
  return svgToDataUri(
    `<svg width="612" height="818" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 612 818"> <style> @font-face { font-family: 'Inter', sans-serif;; src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600'); } .t { font-family: 'Inter', sans-serif; } .s14 {font-size: 14px;} .s15 {font-size: 15px;} .b { font-weight: 600;} .r { font-weight: 400;} </style> <rect fill="${
      theme.background
    }" width="612" height="818" rx="10" ry="10" x="0" y="0" /> <rect fill="${
      theme.cardBackground
    }" width="364" height="487" rx="10" ry="10" x="124" y="165"/> <path d="M124 194H301C303.761 194 306 196.239 306 199V221C306 223.761 303.761 226 301 226H124V194Z" fill="${
      theme.text
    }"/> <text x="140" y="216" style="font-size: 16px;" fill="${
      theme.cardBackground
    }" class="b t">INSTRUCTIONS</text> <g> <text class="b t s15" fill="${
      theme.text
    }"> <tspan x="149" y="266">1. GET IMAGE OF</tspan> <tspan x="164" y="286">${title}</tspan> <tspan x="149" y="316">2. MOVE MOUSE TO POSITION</tspan> <tspan x="164" y="336">(${
      coords[0]
    }, ${
      coords[1]
    })</tspan> <tspan x="149" y="366">3. RIGHT-CLICK</tspan> <tspan x="149" y="396">4. HOVER OVER SAVE AS</tspan> <tspan x="149" y="426">5. TAKE A SCREENSHOT</tspan> <tspan x="149" y="456">6. SHARE YOUR MASTERPIECE</tspan> </text> </g> <text class="b t s14" x="234" y="580" fill="${
      theme.text
    }">RIGHT CLICK SAVE ART #${data.tokenId
      .toString(16)
      .toUpperCase()}</text> <text class="r t s14" x="234" y="604" fill="${
      theme.text
    }">${title}</text>
      ${qrCodeSVG}
      </svg>`
  );
};

export const toNFTMediaItem = (nft: RCSANFT): NFTMediaItem => ({
  mediaUrl: nft.underlyingNftMediaUrl,
  mediaUrlOptimised:
    nft.underlyingNftMediaUrlOptimised ?? nft.underlyingNftMediaUrl,
  tokenId: nft.tokenId,
  underlyingNftTokenId: nft.underlyingNftTokenId,
  name: nft.underlyingNftName,
  mimeType: nft.underlyingNftMimeType,
  verified: nft.verified ?? false,
  theme: nft.theme,
  underlyingNftSymbol: nft.underlyingNftSymbol,
  qr: nft.qr,
});

export const isMinted = (nft?: RCSANFT) => {
  if (nft == null) return false;

  return nft.creatorAddress !== nullAddress || nft.isLocked === true;
};
