const createKeccakHash = require('keccak');
export const getEllipsisTxt = (str:string, n = 6) : string => {
  if (str) {
    return `${str.slice(0, n)}...${str.slice(str.length - n)}`;
  }
  return "";
};

export const idToPaddedHex = (id : number) => {
  return id.toString(16).padStart(64, "0")
}

export const svgToDataUri = (svg: string) => {
  return `data:image/svg+xml;base64,${Buffer.from(svg, 'ascii').toString('base64')}`;
}

export const toChecksumAddress = (address: string) => {
  address = address.toLowerCase().replace('0x', '');
  let hash = createKeccakHash('keccak256').update(address).digest('hex');
  let ret = '0x';

  for (let i = 0; i < address.length; i++) {
    if (parseInt(hash[i], 16) >= 8) {
      ret += address[i].toUpperCase();
    } else {
      ret += address[i];
    }
  }

  return ret;
}