import { Network } from "./types";
export const networkConfigs: Network = {
    "0x1": {
      chainId: 1,
      chainName: "Ethereum Mainnet",
      currencySymbol: "ETH",
      blockExplorerUrl: "https://etherscan.io",
      wrapped: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    },
    "0x3": {
      chainId: 3,
      chainName: "Ropsten",
      currencySymbol: "ropETH",
      blockExplorerUrl: "https://ropsten.etherscan.io",
    },
    "0x4": {
      chainId: 4,
      chainName: "Rinkeby",
      currencySymbol: "rETH",
      blockExplorerUrl: "https://rinkeby.etherscan.io",
    },
    "0x5": {
      chainId: 5,
      chainName: "Goerli",
      currencySymbol: "ETH",
      blockExplorerUrl: "https://goerli.etherscan.io",
    },
    "0x539": {
      chainId: 0,
      chainName: "Local Chain",
      currencyName: "ETH",
      currencySymbol: "ETH",
      rpcUrl: "http://127.0.0.1:7545",
      blockExplorerUrl: "https://etherscan.io"
    }, 
    "0x89": {
      chainId: 137,
      chainName: "Polygon Mainnet",
      currencyName: "MATIC",
      currencySymbol: "MATIC",
      rpcUrl: "https://rpc-mainnet.maticvigil.com/",
      blockExplorerUrl: "https://explorer-mainnet.maticvigil.com",
      wrapped: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    }
  };
  
  export const getChainById = (chain: string ) => networkConfigs[chain as keyof Network]?.chainId;
  export const getExplorer = (chain: string) => networkConfigs[chain as keyof Network]?.blockExplorerUrl;
  
  export const getChainNameByKey = (chain: string) : string => networkConfigs[chain as keyof Network]?.chainName;
  export const getChainByKey = (chain: string)  => networkConfigs[chain as keyof Network];