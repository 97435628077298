import React, { useContext } from "react";
import Image from "next/image";
import Spacer from "./Spacer";
import styles from "styles/Cart.module.scss";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { MediaQueryContext } from "../context/mediaQuery/context";

interface Props {
  onClose(): void;
}

const EmptyCart: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    state: { tablet },
  } = useContext(MediaQueryContext);

  return (
    <div
      className={clsx("column ai-center jc-center h-full", styles.emptyCart)}
    >
      <Spacer flex={1} />
      <Image
        priority
        src={"/warning.svg"}
        width={40}
        height={40}
        alt={"warning"}
      />
      <Spacer height={24} />
      <div className={styles.emptyText}>{t("cart.findNFTGuide")}</div>
      <Spacer height={24} />
      <div
        className={clsx("row ai-center jc-center", styles.galleryLinkButton)}
        onClick={() => {
          onClose();

          setTimeout(() => router.push("/gallery").then(), 250);
        }}
      >
        <div className={styles.galleryLink}>
          {t("cart.seeGalleryForExamples")}
        </div>
        <Spacer width={8} />
        <Image src={"/arrow-yellow.svg"} width={14} height={14} alt={"arrow"} />
      </div>
      <Spacer flex={1} />
      {tablet && (
        <>
          <div className={styles.separator} />
          <Spacer height={16} />
          <div
            className={clsx("cursor-pointer", styles.closeButton)}
            onClick={onClose}
          >
            {t("close")}
          </div>
          <Spacer height={16} />
        </>
      )}
    </div>
  );
};

export { EmptyCart };
