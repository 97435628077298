import { CartItem, RCSANFT } from "./types";
import axios from "axios";
import React, { useCallback } from "react";
import { CartContext } from "../context/cart/context";
import { NotificationContext } from "../context/notifications/context";
import {
  NotificationType,
  showNotification,
} from "../context/notifications/actions";
import {
  addItemToCart,
  removeCartItem,
  setExpandingStatus,
  setSlidingIn,
  updateCartItem,
} from "../context/cart/actions";
import { delay, getRCSATitle } from "./utils";
import { ExpandingStatus } from "../context/cart/state";
import { useTranslation } from "react-i18next";
import { CART_MAX_ITEMS } from "./constants";
import { isMinted } from "./rcsNft";

export const getNFTMetadata = async ({
  contractAddress,
  tokenId,
}: {
  contractAddress: string;
  tokenId: string;
}): Promise<RCSANFT | null> => {
  const response = await axios.get(
    `/api/findNft?contractAddress=${contractAddress}&tokenId=${tokenId}`
  );

  return response.data;
};

export const useAddToCart = () => {
  const cart = React.useContext(CartContext);
  const notification = React.useContext(NotificationContext);
  const { t } = useTranslation();

  return useCallback(
    async ({
      tokenId,
      contractAddress,
      updateUrl,
    }: {
      tokenId: string;
      contractAddress: string;
      updateUrl?(url: string): void;
    }): Promise<void> => {
      if (cart.state.items.length >= CART_MAX_ITEMS) {
        updateUrl?.("");
        notification.dispatch(
          showNotification({
            text: t("cart.reachedLimit"),
            type: NotificationType.error,
          })
        );

        return;
      }

      const id = `${contractAddress}_${tokenId}`;
      cart.dispatch(addItemToCart({ id }));
      cart.dispatch(setSlidingIn(true));

      await delay(450);

      updateUrl?.("");
      cart.dispatch(setExpandingStatus(ExpandingStatus.small));

      if (cart.state.items.find((item) => item.id === id)) {
        notification.dispatch(
          showNotification({
            text: t("error.nftAlreadyAdded"),
            type: NotificationType.error,
          })
        );
        return;
      }

      try {
        const data = await getNFTMetadata({
          contractAddress,
          tokenId,
        });

        if (data) {
          if (isMinted(data)) {
            notification.dispatch(
              showNotification({
                text: t("error.alreadyCreatedNFT", {
                  title: getRCSATitle({
                    underlyingNftTokenId: data.underlyingNftTokenId,
                    underlyingNftSymbol: data.underlyingNftSymbol,
                    underlyingNftName: data.underlyingNftName,
                  }),
                  url: `/token/${data.tokenId}`,
                }),
                type: NotificationType.errorCloseManually,
              })
            );
            cart.dispatch(removeCartItem(id));

            return;
          }

          cart.dispatch(updateCartItem({ id, nft: data }));
        } else {
          notification.dispatch(
            showNotification({
              text: t("error.notFoundNFT"),
              type: NotificationType.error,
            })
          );
          cart.dispatch(removeCartItem(id));
        }
      } catch (e) {
        notification.dispatch(
          showNotification({
            text: t("error.notFoundNFT"),
            type: NotificationType.error,
          })
        );
        cart.dispatch(removeCartItem(id));
      }
    },
    [cart, notification, t]
  );
};

const CartIdsStorageKey = "cartIds";

export const persistCartIds = (cartItems: CartItem[]) => {
  const ids = cartItems.map((item) => item.id);
  localStorage.setItem(CartIdsStorageKey, JSON.stringify(ids));
};

export const clearSavedCartIds = () =>
  localStorage.removeItem(CartIdsStorageKey);

export const getSavedCartIds = (): string[] => {
  const cardIdsString = localStorage.getItem(CartIdsStorageKey);
  if (cardIdsString) {
    try {
      return JSON.parse(cardIdsString);
    } catch (e) {
      console.error("getSavedCartIds", e);
      return [];
    }
  }

  return [];
};

export const hasMintedNFT = (cartItems: CartItem[]) => {
  return cartItems.some((item) => isMinted(item.nft));
};
