import React, { useMemo } from "react";
import { getTheme } from "../utils/theme";
import { getQRCodeUrl } from "../utils/qrCode";
import { getRCSATitle } from "../utils/utils";
import Spacer from "./Spacer";
import Image from "next/image";
import { RCSANFT } from "../utils/types";
import { DEFAULT_XNFT_COORDINATE } from "../utils/constants";

const originalCardWidth = 366;

interface Props {
  data: RCSANFT;
  width: number;
  forceToUseQRURL?: boolean;
  onMediaLoaded?(): void;
}

const XNFT: React.FC<Props> = ({
  width,
  forceToUseQRURL,
  data,
  onMediaLoaded,
}) => {
  const theme = getTheme(data.theme);
  const ratio = width / originalCardWidth;

  const mediaSize = useMemo(() => originalCardWidth * ratio, [ratio]);
  const qrCodeSize = useMemo(() => 90 * ratio, [ratio]);
  const qrCodeImageDataUrl = useMemo(
    () =>
      forceToUseQRURL
        ? getQRCodeUrl({ tokenId: data.tokenId, theme })
        : data.qr || getQRCodeUrl({ tokenId: data.tokenId, theme }),
    [forceToUseQRURL, data.tokenId, data.qr, theme]
  );

  const liStyle = useMemo(
    () => ({
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: 13 * ratio,
      lineHeight: `${26 * ratio}px`,
    }),
    [ratio]
  );

  return (
    <div
      className={"column relative"}
      style={{
        background: theme.cardBackground,
        borderRadius: 10 * ratio,
        width: mediaSize,
        height: 486.64 * ratio,
        flexShrink: 0,
      }}
    >
      <div
        style={{
          width: mediaSize,
          height: mediaSize,
          color: theme.text,
        }}
      >
        <Spacer height={29 * ratio} />
        <div
          style={{
            width: 182 * ratio,
            height: 32 * ratio,
            fontFamily: "Inter",
            fontWeight: 700,
            background: theme.background,
            borderRadius: `0 ${5 * ratio}px ${5 * ratio}px 0`,
            padding: `${3 * ratio}px ${44 * ratio}px ${3 * ratio}px ${
              16 * ratio
            }px`,
            fontSize: 16 * ratio,
            lineHeight: `${26 * ratio}px`,
            color: theme.cardBackground,
          }}
        >
          INSTRUCTIONS
        </div>
        <Spacer height={40 * ratio} />
        <div style={{ paddingLeft: 25 * ratio }} className={"column"}>
          <div className={"row ai-start"}>
            <div style={liStyle}>1.</div>
            <Spacer width={4 * ratio} />
            <div style={liStyle} className={"column"}>
              <div>GET IMAGE OF</div>
              <div>
                {getRCSATitle({
                  underlyingNftName: data.underlyingNftName,
                  underlyingNftTokenId: data.underlyingNftTokenId,
                  underlyingNftSymbol: data.underlyingNftSymbol,
                  withPrefixX: true,
                  withPrefixRCS: false,
                })}
              </div>
            </div>
          </div>
          <div className={"row ai-start"}>
            <div style={liStyle}>2.</div>
            <Spacer width={4 * ratio} />
            <div style={liStyle} className={"column"}>
              <div>MOVE MOUSE TO POSITION</div>
              <div>
                ({data.xPos ?? DEFAULT_XNFT_COORDINATE.xPos},{" "}
                {data.yPos ?? DEFAULT_XNFT_COORDINATE.yPos})
              </div>
            </div>
          </div>
          <div className={"row ai-start"}>
            <div style={liStyle}>3.</div>
            <Spacer width={4 * ratio} />
            <div style={liStyle}>RIGHT-CLICK</div>
          </div>
          <div className={"row ai-start"}>
            <div style={liStyle}>4.</div>
            <Spacer width={4 * ratio} />
            <div style={liStyle}>HOVER OVER SAVE AS</div>
          </div>
          <div className={"row ai-start"}>
            <div style={liStyle}>5.</div>
            <Spacer width={4 * ratio} />
            <div style={liStyle}>TAKE A SCREENSHOT</div>
          </div>
          <div className={"row ai-start"}>
            <div style={liStyle}>6.</div>
            <Spacer width={4 * ratio} />
            <div style={liStyle}>SHARE YOUR MASTERPIECES</div>
          </div>
        </div>
      </div>
      <div
        className={"row ai-center jc-center"}
        style={{
          padding: `0 ${12 * ratio}px`,
          flex: 1,
        }}
      >
        <Image
          src={qrCodeImageDataUrl}
          width={qrCodeSize}
          height={qrCodeSize}
          alt={"QRCode"}
          onLoadedData={onMediaLoaded}
        />
        <div style={{ marginLeft: 12 * ratio }}>
          <div
            style={{
              color: theme.text,
              marginBottom: 8 * ratio,
              fontSize: 14 * ratio,
              fontWeight: 600,
              lineHeight: `${14 * ratio}px`,
            }}
          >
            RIGHT CLICK SAVE ART #{data.tokenId?.toString(16).toUpperCase()}
          </div>
          <div className={"row ai-center jc-start"}>
            <div
              style={{
                color: theme.text,
                fontSize: 14 * ratio,
                fontWeight: 400,
                lineHeight: `${14 * ratio}px`,
              }}
            >
              {getRCSATitle({
                underlyingNftName: data.underlyingNftName,
                underlyingNftTokenId: data.underlyingNftTokenId,
                underlyingNftSymbol: data.underlyingNftSymbol,
                withPrefixRCS: false,
              })}
            </div>
            <Spacer flex={0.15} />
            {data.verified && (
              <Image
                priority
                src={"/nft-card/verified.svg"}
                width={20 * ratio}
                height={20 * ratio}
                alt={"verified"}
                layout={"fixed"}
              />
            )}
          </div>
        </div>
        <Spacer flex={1} />
      </div>
    </div>
  );
};

export { XNFT };
