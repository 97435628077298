import React, { useState } from "react";
import { animated, easings, Spring } from "react-spring";

interface Props {
  hide: boolean;
  background: string;
  color: string;
  text: string;
  tooltipWidth: number;
  staticTooltip: boolean;
}

const GuideTooltip: React.FC<Props> = ({
  hide,
  background,
  color,
  text,
  tooltipWidth,
  staticTooltip,
}) => {
  const [mouseOver, setMouseOver] = useState<boolean>();

  return (
    <div
      onMouseLeave={() => setMouseOver(false)}
      onMouseOver={() => setMouseOver(true)}
      style={{
        position: "absolute",
        right: 0,
        width: 70,
        height: 70,
        opacity: hide ? 0 : 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {(staticTooltip || mouseOver != null) && (
        <Spring
          from={{
            top: staticTooltip || mouseOver ? -172 : -72,
            opacity: staticTooltip || mouseOver ? 0 : 1,
          }}
          to={{
            top: staticTooltip || mouseOver ? -46 : -172,
            opacity: staticTooltip || mouseOver ? 1 : 0,
          }}
          config={{ duration: 700, easing: easings.easeOutQuart }}
        >
          {(animatedStyles) => {
            return (
              <animated.div
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  opacity: animatedStyles.opacity,
                  top: animatedStyles.top,
                }}
                className={"pointer-events-none"}
              >
                <div className={"column ai-center"}>
                  <div
                    style={{
                      width: tooltipWidth,
                      fontSize: 13,
                      lineHeight: "20px",
                      color,
                      padding: "16px 24px",
                      background,
                      boxShadow: "0px 1px 30px rgba(0, 0, 0, 0.15)",
                      borderRadius: 10,
                    }}
                  >
                    {text}
                  </div>
                  <div
                    style={{
                      width: 0,
                      height: 0,
                      borderLeft: "6px solid transparent",
                      borderRight: "6px solid transparent",
                      borderTop: `6px solid ${background}`,
                    }}
                  />
                </div>
              </animated.div>
            );
          }}
        </Spring>
      )}
      <Spring
        from={{ scale: 1 }}
        to={[{ scale: 3.2 }, { scale: 1 }]}
        loop
        config={{ duration: 900, easing: easings.easeOutQuart }}
      >
        {(animatedStyles) => {
          return (
            <animated.div
              style={{
                ...animatedStyles,
                width: 20,
                height: 20,
                borderRadius: "50%",
                background: background,
                position: "absolute",
                opacity: 0.3,
              }}
              className={"pointer-events-none"}
            />
          );
        }}
      </Spring>
      <Spring
        from={{ scale: 1 }}
        to={[{ scale: 1.6 }, { scale: 1 }]}
        loop
        config={{
          duration: 900,
          easing: easings.easeOutQuart,
        }}
      >
        {(animatedStyles) => {
          return (
            <animated.div
              style={{
                ...animatedStyles,
                width: 20,
                height: 20,
                borderRadius: "50%",
                background,
                position: "absolute",
              }}
              className={"pointer-events-none"}
            />
          );
        }}
      </Spring>
    </div>
  );
};

export { GuideTooltip };
