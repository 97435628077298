import { CartItem } from "../utils/types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { NumberSize } from "re-resizable";
import { NFTMedia } from "./NFTMedia";
import { getTheme } from "../utils/theme";
import { Loading } from "./Loading";
import { XNFT } from "./XNFT";
import { toNFTMediaItem } from "../utils/rcsNft";

interface Props {
  item: CartItem;
  delta?: NumberSize;
  onQRLoaded?(): void;
  onMediaLoaded?(): void;
  useImageIfVideo?: boolean;
  imgId?: string;
  lazyLoadImage?: boolean;
  forceToUseQRURL?: boolean;
  showXNFT?: boolean;
  useOptimisedSrc?: boolean;
}

const NFTRCSA: React.FC<Props> = ({
  item,
  delta,
  onMediaLoaded,
  onQRLoaded,
  useImageIfVideo = false,
  imgId,
  lazyLoadImage = true,
  forceToUseQRURL = false,
  showXNFT = false,
  useOptimisedSrc = true,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  const updateWidth = useCallback(() => {
    setWidth(ref.current?.clientWidth ?? 0);
  }, []);

  useEffect(() => {
    updateWidth();

    window.removeEventListener("resize", updateWidth);
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [delta, updateWidth, item.nft]);

  const originalWidth = 402;
  const originalHeight = 203;
  const ratio = width / originalWidth;
  const height = originalHeight * ratio;

  return (
    <div
      ref={ref}
      style={{
        height,
        borderRadius: 10,
        background: item.nft ? getTheme(item.nft.theme).background : "#F7F3EA",
      }}
      className={"column ai-center jc-center w-full"}
    >
      <div style={{ transform: `scale(${ratio})` }}>
        {!item.nft && <Loading size={50} />}
        {item.nft &&
          (showXNFT ? (
            <XNFT
              data={item.nft}
              width={100.26}
              onMediaLoaded={onMediaLoaded}
              forceToUseQRURL={forceToUseQRURL}
            />
          ) : (
            <NFTMedia
              width={100.26}
              item={toNFTMediaItem(item.nft)}
              useImageIfVideo={useImageIfVideo}
              onMediaLoaded={onMediaLoaded}
              onQRLoaded={onQRLoaded}
              imgId={imgId}
              lazyLoadImage={lazyLoadImage}
              forceToUseQRURL={forceToUseQRURL}
              useOptimisedSrc={useOptimisedSrc}
            />
          ))}
      </div>
    </div>
  );
};

export { NFTRCSA };
