import retry from "async-retry";
import { NFTSvgTheme } from "./types";
import { BASE_URL } from "./constants";

export const getQRCodeSVGString = ({
  tokenId,
  qr,
  qrBackground,
}: {
  tokenId: number;
  qr: string;
  qrBackground: string;
}): Promise<string> => {
  return retry(async () => {
    const url = `${BASE_URL}/token/${tokenId}`;
    const qrCodeParams = [
      `color=${qr.replace("#", "")}`,
      `bgcolor=${qrBackground.replace("#", "")}`,
      "format=svg",
      "size=90x90",
      "qzone=2",
      `data=${url}`,
    ];
    const queryString = qrCodeParams.join("&");
    const response = await fetch(
      `https://api.qrserver.com/v1/create-qr-code/?${queryString}`
    );

    return response.text();
  });
};

export const getQRCodeUrl = ({
  tokenId,
  theme,
}: {
  tokenId: number;
  theme: NFTSvgTheme;
}): string => {
  const data = `${BASE_URL}/token/${tokenId}`;
  const qrCodeParams = [
    `color=${theme.qr.replace("#", "")}`,
    `bgcolor=${theme.qrBackground.replace("#", "")}`,
    "format=png",
    "size=90x90",
    "qzone=2",
    `data=${data}`,
  ];
  const queryString = qrCodeParams.join("&");

  return `https://api.qrserver.com/v1/create-qr-code/?${queryString}`;
};
