import React from "react";
import styles from "../styles/Loading.module.scss";
import Image from "next/image";

interface Props {
  size?: number;
}

const Loading: React.FC<Props> = ({ size = 60 }) => (
  <div className={styles.loadingImage}>
    <Image
      priority
      src={"/loading.svg"}
      width={size}
      height={size}
      alt={"loading"}
      layout={"fixed"}
    />
  </div>
);

export { Loading };
