import React, { useCallback, useContext, useState } from "react";
import styles from "styles/GiftAddressModal.module.scss";
import Spacer from "./Spacer";
import Image from "next/image";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { isValidAddress } from "../utils/utils";
import { RCSAModal } from "./RCSAModal";
import { MediaQueryContext } from "../context/mediaQuery/context";

interface Props {
  onClose(): void;
  onSetGiftAddress(address: string): void;
  show: boolean;
}

const GiftAddressModal: React.FC<Props> = ({
  onClose,
  onSetGiftAddress,
  show,
}) => {
  const { t } = useTranslation();
  const [address, setAddress] = useState("");
  const [focusing, setFocusing] = useState(false);
  const validAddress = isValidAddress(address);
  const onGift = useCallback(() => {
    if (validAddress) {
      onSetGiftAddress(address);
    }
  }, [validAddress, address, onSetGiftAddress]);

  const onKeyUp = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && address.trim().length > 0) {
        onGift();
      }
    },
    [address, onGift]
  );
  const {
    state: { tablet },
  } = useContext(MediaQueryContext);

  if (!show) return null;

  return (
    <RCSAModal>
      <div className={"row ai-center"}>
        <div className={styles.title}>{t("gift.title")}</div>
        <Spacer flex={1} />
        <div className={"cursor-pointer"} onClick={onClose}>
          <Image
            priority
            src={"/close-green.svg"}
            width={tablet ? 22 : 29}
            height={tablet ? 22 : 29}
            layout={"fixed"}
            alt={"close"}
            className={"pointer-events-none"}
          />
        </div>
      </div>
      <Spacer height={40} />
      <div
        className={clsx("row ai-center", styles.inputContainer, {
          [styles.inputFocusingContainer]: focusing,
        })}
      >
        <Image
          priority
          src={"/search.svg"}
          width={18}
          height={18}
          alt={"search"}
          layout={"fixed"}
          className={clsx(styles.searchIcon, {
            [styles.focusing]: focusing,
          })}
        />
        <Spacer width={16} />
        <input
          value={address}
          className={styles.input}
          placeholder={t("gift.enterGiftUrl")}
          onFocus={() => setFocusing(true)}
          onBlur={() => setFocusing(false)}
          onChange={({ target: { value } }) => setAddress(value.trim())}
          onKeyUp={onKeyUp}
        />
      </div>

      {address ? (
        validAddress ? (
          <>
            <Spacer height={16} />
            <div
              className={clsx("row ai-center jc-center", styles.validAddress)}
            >
              <Image
                priority
                src={"/check-green.svg"}
                width={19}
                height={19}
                layout={"fixed"}
                alt={"valid icon"}
              />
              <Spacer width={8} />
              <span>{t("gift.validAddress")}</span>
            </div>
            <Spacer height={24} />
          </>
        ) : (
          <>
            <Spacer height={16} />
            <div
              className={clsx("row ai-center jc-center", styles.invalidAddress)}
            >
              <Image
                priority
                src={"/close-red.svg"}
                width={19}
                height={19}
                layout={"fixed"}
                alt={"valid icon"}
              />
              <Spacer width={8} />
              <span>{t("gift.invalidAddress")}</span>
            </div>
            <Spacer height={24} />
          </>
        )
      ) : (
        <Spacer height={40} />
      )}

      <button
        className={styles.button}
        onClick={onGift}
        disabled={!address || !isValidAddress}
      >
        {t("gift.text")}
      </button>
    </RCSAModal>
  );
};

export { GiftAddressModal };
