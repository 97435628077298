export const nullAddress = "0x0000000000000000000000000000000000000000";
export const COLLECTION = "RightClickSaveArt";
export const CONTRACT_ADDRESS = process.env.NEXT_PUBLIC_CONTRACT_ADDRESS ?? "";
export const CLOUD_FUNCTIONS_URL =
  process.env.NEXT_PUBLIC_VERCEL_URL ?? "http://localhost:3000";
export const BASE_URL =
  process.env.NEXT_PUBLIC_BASE_URL ?? "https://staging.rightclicksaveart.com";
export const CART_MAX_ITEMS = 20;
export const MARKET_URL_REGEX =
  "(opensea.io\\/assets\\/(ethereum\\/|)|looksrare.org\\/collections\\/|nft.coinbase.com\\/nft\\/ethereum\\/)0x\\w{40}\\/\\w+";
export const MARKET_COLLECTION_URL_REGEX =
  "(opensea.io\\/collection\\/[a-z\\-]+$)|(looksrare.org\\/collections\\/0x\\w{40}$)|(nft.coinbase.com\\/collection\\/ethereum\\/0x\\w{40}$)";
export const SOJI_TWITTER = "https://twitter.com/0xsoji";
export const DEFAULT_XNFT_COORDINATE = { xPos: 382, yPos: 115 };
export const MINT_FEE = process.env.NEXT_PUBLIC_MINT_FEE || "42000000000000000";
export const MINT_FEE_NUMBER = Number(MINT_FEE) / 1000000000000000000;
