import React from "react";

interface Props {
  width?: number;
  height?: number;
  flex?: number;
  className?: string;
  flexShrink?: number;
}

const Spacer: React.FC<Props> = ({
  flex,
  width = 0,
  height = 0,
  className = "",
  flexShrink = 0,
}) => {
  return (
    <div style={{ flex, width, height, flexShrink }} className={className} />
  );
};

export default Spacer;
