import React, { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import styles from "../styles/Cart.module.scss";
import Spacer from "./Spacer";
import { NFTRCSA } from "./NFTRCSA";
import {
  changeNFTTheme,
  removeCartItem,
  updateCartItem,
} from "../context/cart/actions";
import Image from "next/image";
import { CartItem, RCSANFT } from "../utils/types";
import { NumberSize } from "re-resizable";
import { CartContext } from "../context/cart/context";
import { useTranslation } from "react-i18next";
import { getRCSATitle } from "../utils/utils";
import { GenerateNFTImage } from "./GenerateNFTImage";
import { getThemes } from "../utils/theme";
import { getEllipsisTxt } from "../utils/formatters";
import { doc, onSnapshot } from "@firebase/firestore";
import { db } from "../utils/firebase";
import { isMinted } from "../utils/rcsNft";

interface Props {
  item: CartItem;
  numberSize?: NumberSize;
}

const CartLineItem: React.FC<Props> = ({ item, numberSize }) => {
  const { t } = useTranslation();
  const cart = React.useContext(CartContext);
  const onDeleteCartItem = useCallback(
    (item: CartItem) => cart.dispatch(removeCartItem(item.id)),
    [cart]
  );
  const [generatingImage, setGeneratingImage] = useState(false);

  const onGenerated = useCallback(
    async (dataUrl: string) => {
      const link = document.createElement("a");
      link.download = `${getRCSATitle({
        underlyingNftName: item.nft!.underlyingNftName,
        underlyingNftTokenId: item.nft!.underlyingNftTokenId,
        underlyingNftSymbol: item.nft!.underlyingNftSymbol,
      })}.png`;
      link.href = dataUrl;
      link.click();
      link.remove();
    },
    [item]
  );

  const minted = isMinted(item.nft);
  useEffect(() => {
    if (minted) {
      return;
    }

    const unsub = onSnapshot(doc(db, "rcsa", item.id), (doc) => {
      const data = doc.data() as RCSANFT;

      if (isMinted(data)) {
        cart.dispatch(updateCartItem({ id: item.id, nft: data }));
      }
    });

    return () => {
      unsub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.id, minted]);

  const themes = useMemo(() => getThemes(), []);

  return (
    <>
      <div className={clsx("column", styles.cartItem)}>
        <div
          className={styles.cartItemTitle}
          style={{ opacity: minted ? 0.5 : 1 }}
        >
          {item.nft ? (
            getRCSATitle({
              underlyingNftSymbol: item.nft!.underlyingNftSymbol,
              underlyingNftTokenId: item.nft!.underlyingNftTokenId,
              underlyingNftName: item.nft!.underlyingNftName,
            })
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
        <Spacer height={4.94} />
        <div
          className={clsx(
            "column ai-center jc-center relative",
            styles.nftContainer
          )}
        >
          <NFTRCSA item={item} delta={numberSize} lazyLoadImage={false} />

          {minted && item.nft && (
            <div className={styles.mintedContainer}>
              {t("cart.thisHasBeenMinted", {
                contractAddress: getEllipsisTxt(item.nft!.creatorAddress),
              })}
            </div>
          )}
        </div>
        <Spacer height={14} />
        <div className={"row ai-center"}>
          <div className={"row ai-center"}>
            {item.nft &&
              Object.keys(themes).map((id) => {
                const theme = themes[id];
                const selected = id === item.nft!.theme;

                return (
                  <React.Fragment key={theme.id}>
                    <div
                      className={clsx(
                        "cursor-pointer column ai-center jc-center",
                        styles.bgColorItem
                      )}
                      style={{
                        backgroundColor: theme.background,
                      }}
                      onClick={() => {
                        cart.dispatch(changeNFTTheme(item.id, theme.id));
                      }}
                      data-testid={theme.id}
                    >
                      {selected && (
                        <Image
                          priority
                          src={
                            theme.background === "#000000"
                              ? "/tick-white.svg"
                              : "/tick.svg"
                          }
                          width={9.66}
                          height={9.66}
                          alt={"tick"}
                          layout={"fixed"}
                        />
                      )}
                    </div>
                    <Spacer width={8.78} />
                  </React.Fragment>
                );
              })}
          </div>
          <Spacer flex={1} />
          {generatingImage && (
            <>
              <div className={styles.downloadingText}>
                {t("generatingImage")}
              </div>
              <Spacer width={12} />
            </>
          )}
          {item.nft && (
            <>
              <div
                onClick={() => !generatingImage && setGeneratingImage(true)}
                className={"cursor-pointer"}
              >
                <Image
                  priority
                  src={"/download.svg"}
                  width={21.07}
                  height={21.07}
                  alt={"Download"}
                  className={"pointer-events-none"}
                />
              </div>
              <Spacer width={21.07} />
            </>
          )}
          <div
            className={"cursor-pointer"}
            onClick={() => onDeleteCartItem(item)}
          >
            <Image
              priority
              src={"/trash.svg"}
              width={21.07}
              height={21.07}
              alt={"delete"}
              layout={"fixed"}
              className={"pointer-events-none user-select-none"}
            />
          </div>
        </div>
      </div>

      {item.nft && generatingImage && (
        <GenerateNFTImage
          data={item.nft}
          onGenerated={onGenerated}
          onDone={() => setGeneratingImage(false)}
        />
      )}
    </>
  );
};

export { CartLineItem };
