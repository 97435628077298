export const isBase64Data = (dataUrl: string) => dataUrl.startsWith("data:");

export const isVideo = (mimeType: string) =>
  mimeType.startsWith("video/") ?? false;
export const isProxied = (url: string) =>
  url.startsWith("https://ik.imagekit.io") ?? false;

const defaultTransformation = "tr=w-400,h-400,c-at_max";

export const imageProxy = (
  url: string,
  mimeType: string,
  transformation: string = defaultTransformation
) => {
  if (isVideo(mimeType) || isBase64Data(url) || isProxied(url)) return url;

  return `${process.env.NEXT_PUBLIC_IMAGEKIT_URL!}${encodeURIComponent(
    url
  )}?${transformation}`;
};

export const videoProxy = (
  url: string,
  transformation: string = defaultTransformation
) => {
  if (isBase64Data(url) || isProxied(url)) return url;

  return `${process.env.NEXT_PUBLIC_IMAGEKIT_URL!}${url}?${transformation}`;
};

// ref: https://stackoverflow.com/a/46815575
export const svgString2Image = (
  svgDataUrl: string,
  width: number,
  height: number,
  format: string = "png"
): Promise<string> => {
  return new Promise((resolve, reject) => {
    // create canvas in memory(not in DOM)
    const canvas = document.createElement("canvas");
    // get canvas context for drawing on canvas
    const context = canvas.getContext("2d");
    // set canvas size
    canvas.width = width;
    canvas.height = height;
    // create image in memory(not in DOM)
    const image = new Image();
    // later when image loads run this
    image.onload = () => {
      // async (happens later)
      // clear canvas
      context?.clearRect(0, 0, width, height);
      // draw image with SVG data to canvas
      context?.drawImage(image, 0, 0, width, height);
      // snapshot canvas as png
      const pngData = canvas.toDataURL("image/" + format);
      // pass png data URL to callback
      resolve(pngData);
    }; // end async
    image.onerror = reject;
    // start loading SVG data into in memory image
    image.src = svgDataUrl;
  });
};
