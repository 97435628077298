// ref: https://stackoverflow.com/a/52357595
import { videoProxy } from "./imageKit";

export const extractFramesFromVideo = async (
  videoUrl: string,
  { at = 0 }: { at: number }
) => {
  return new Promise<string>(async (resolve) => {
    // fully download it first (no buffering):
    const videoBlob = await fetch(videoProxy(videoUrl)).then((r) => r.blob());
    const videoObjectUrl = URL.createObjectURL(videoBlob);
    const video = document.createElement("video");
    const w = 366;
    video.width = w;
    video.height = w;

    let seekResolve: (value: unknown) => void;
    video.addEventListener("seeked", async function () {
      if (seekResolve) seekResolve(null);
    });

    video.src = videoObjectUrl;

    // workaround chromium metadata bug (https://stackoverflow.com/q/38062864/993683)
    while (
      (video.duration === Infinity || isNaN(video.duration)) &&
      video.readyState < 2
    ) {
      await new Promise((r) => setTimeout(r, 1000));
      video.currentTime = 10000000 * Math.random();
    }

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = w;
    canvas.height = w;

    video.currentTime = at;
    await new Promise((r) => (seekResolve = r));

    context?.drawImage(video, 0, 0, w, w);
    const base64ImageData = canvas.toDataURL();

    video.remove();
    canvas.remove();

    resolve(base64ImageData);
  });
};
