import { WalletState } from "./state";
import { ActionType, WalletActions } from "./actions";

export function walletReducer(
  state: WalletState,
  action: WalletActions
): WalletState {
  switch (action.type) {
    case ActionType.ShowConnectWalletModal: {
      return {
        ...state,
        showConnectWalletModal: action.payload,
      };
    }

    default:
      return state;
  }
}
