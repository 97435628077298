import { ExpandingStatus } from "./state";
import { CartItem, Theme } from "../../utils/types";

export enum ActionType {
  SetExpandingStatus,
  AddCart,
  UpdateCartItem,
  RemoveCartItem,
  ChangeTheme,
  SetSlidingIn,
  UpdateCartItems,
  ClearCartItems,
}

export interface SetExpanding {
  type: ActionType.SetExpandingStatus;
  payload: ExpandingStatus;
}

export interface AddItemToCart {
  type: ActionType.AddCart;
  payload: CartItem;
}

export interface UpdateCartItem {
  type: ActionType.UpdateCartItem;
  payload: Partial<CartItem>;
}

export interface RemoveCartItem {
  type: ActionType.RemoveCartItem;
  payload: string;
}

export interface UpdateCartItems {
  type: ActionType.UpdateCartItems;
  payload: CartItem[];
}

export interface ClearCartItems {
  type: ActionType.ClearCartItems;
}

export interface ChangeTheme {
  type: ActionType.ChangeTheme;
  payload: { id: string; themeId: Theme };
}

export interface SetSlidingIn {
  type: ActionType.SetSlidingIn;
  payload: boolean;
}

export type CartActions =
  | SetExpanding
  | AddItemToCart
  | RemoveCartItem
  | ChangeTheme
  | UpdateCartItem
  | SetSlidingIn
  | UpdateCartItems
  | ClearCartItems;

export const setExpandingStatus = (payload: ExpandingStatus): SetExpanding => ({
  type: ActionType.SetExpandingStatus,
  payload,
});

export const addItemToCart = (payload: CartItem): AddItemToCart => ({
  type: ActionType.AddCart,
  payload,
});

export const updateCartItem = (payload: Partial<CartItem>): UpdateCartItem => ({
  type: ActionType.UpdateCartItem,
  payload,
});

export const changeNFTTheme = (id: string, themeId: Theme): ChangeTheme => ({
  type: ActionType.ChangeTheme,
  payload: { id, themeId },
});

export const removeCartItem = (uuid: string): RemoveCartItem => ({
  type: ActionType.RemoveCartItem,
  payload: uuid,
});

export const setSlidingIn = (open: boolean): SetSlidingIn => ({
  type: ActionType.SetSlidingIn,
  payload: open,
});

export const updateCartItems = (cartItems: CartItem[]): UpdateCartItems => ({
  type: ActionType.UpdateCartItems,
  payload: cartItems,
});

export const clearCartItems = (): ClearCartItems => ({
  type: ActionType.ClearCartItems,
});
