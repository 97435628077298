import { CartState } from "./state";
import { ActionType, CartActions } from "./actions";
import { persistCartIds } from "../../utils/cart";

export function cartReducer(state: CartState, action: CartActions): CartState {
  switch (action.type) {
    case ActionType.SetExpandingStatus: {
      return {
        ...state,
        expandingStatus: action.payload,
      };
    }
    case ActionType.SetSlidingIn: {
      return {
        ...state,
        slidingPanelIn: action.payload,
      };
    }
    case ActionType.AddCart: {
      if (state.items.find((item) => item.id === action.payload.id)) {
        return state;
      }

      const newItems = [
        ...state.items.filter((item) => item.id !== action.payload.id),
        action.payload,
      ];

      persistCartIds(newItems);

      return {
        ...state,
        items: newItems,
      };
    }
    case ActionType.UpdateCartItem: {
      return {
        ...state,
        items: state.items.map((item) => {
          if (action.payload.id === item.id) {
            return {
              ...item,
              ...action.payload,
            };
          }

          return item;
        }),
      };
    }
    case ActionType.RemoveCartItem: {
      const newItems = state.items.filter((item) => item.id !== action.payload);

      persistCartIds(newItems);

      return {
        ...state,
        items: newItems,
      };
    }

    case ActionType.ChangeTheme: {
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              nft: {
                ...item.nft!,
                theme: action.payload.themeId,
              },
            };
          }
          return item;
        }),
      };
    }

    case ActionType.UpdateCartItems: {
      return {
        ...state,
        items: action.payload,
      };
    }

    case ActionType.ClearCartItems: {
      return {
        ...state,
        items: [],
      };
    }

    default:
      return state;
  }
}
