import React, { useContext } from "react";
import { connectors } from "fixtures/connectors";
import clsx from "clsx";
import Image from "next/image";
import { WalletContext } from "../context/wallet/context";
import { showConnectWalletModal } from "../context/wallet/actions";
import { NotificationContext } from "../context/notifications/context";
import {
  NotificationType,
  showNotification,
} from "../context/notifications/actions";
import { useMoralis } from "react-moralis";
import styles from "styles/ConnectWalletModal.module.scss";
import { useTranslation } from "react-i18next";
import Spacer from "./Spacer";
import { RCSAModal } from "./RCSAModal";
import { MediaQueryContext } from "../context/mediaQuery/context";

const ConnectWalletModal: React.FC = () => {
  const { authenticate } = useMoralis();
  const wallet = useContext(WalletContext);
  const notification = useContext(NotificationContext);
  const { t } = useTranslation();
  const {
    state: { tablet },
  } = useContext(MediaQueryContext);

  if (!wallet.state.showConnectWalletModal) return null;

  return (
    <RCSAModal>
      <div className={"row ai-center"}>
        <div className={styles.title}>{t("wallet.connect")}</div>
        <Spacer flex={1} />
        <div
          onClick={() => wallet.dispatch(showConnectWalletModal(false))}
          className={"cursor-pointer"}
        >
          <Image
            priority
            src={"/close-green.svg"}
            width={tablet ? 22 : 29}
            height={tablet ? 22 : 29}
            layout={"fixed"}
            alt={"close"}
            className={"pointer-events-none"}
          />
        </div>
      </div>
      <Spacer height={24} />
      <p
        className={styles.agreementMessage}
        dangerouslySetInnerHTML={{
          __html: t("wallet.agreementMessage"),
        }}
      />
      <Spacer height={24} />
      {connectors
        .filter((item) => (tablet ? item.availableOnMobile : true))
        .map(({ title, icon, connectorId }, index) => (
          <React.Fragment key={title}>
            <div
              className={clsx(
                styles.connector,
                "row ai-center jc-center cursor-pointer"
              )}
              onClick={async () => {
                try {
                  await authenticate({
                    // @ts-ignore
                    provider: connectorId,
                    signingMessage:
                      "Connect your wallet to RightClickSave nfts",
                  });
                  window.localStorage.setItem("connectorId", connectorId);
                  wallet.dispatch(showConnectWalletModal(false));
                } catch (e: any) {
                  notification.dispatch(
                    showNotification({
                      text: e.message ?? "Unknown error",
                      type: NotificationType.error,
                    })
                  );
                }
              }}
            >
              <div className={styles.connectorName}>{title}</div>
              <Spacer flex={1} />
              <Image
                src={icon}
                alt={title}
                objectFit="contain"
                width={34}
                height={34}
                layout={"fixed"}
              />
            </div>
            {index !== connectors.length - 1 && <Spacer height={8} />}
          </React.Fragment>
        ))}
    </RCSAModal>
  );
};

export { ConnectWalletModal };
