import { CartItem } from "../../utils/types";

export enum ExpandingStatus {
  close,
  small,
}

export interface CartState {
  expandingStatus: ExpandingStatus;
  slidingPanelIn: boolean;
  items: CartItem[];
}

export const initialState: CartState = {
  expandingStatus: ExpandingStatus.close,
  slidingPanelIn: false,
  items: [],
};
