import React, { useContext } from "react";
import { AbsoluteSlideUp, FadeIn } from "./Animations";
import clsx from "clsx";
import { MediaQueryContext } from "../context/mediaQuery/context";

interface Props {
  children: React.ReactNode;
  onClose?(): void;
  contentClassName?: string;
  noContentWrapper?: boolean;
}

const RCSAModal: React.FC<Props> = ({
  children,
  onClose,
  contentClassName,
  noContentWrapper = false,
}) => {
  const {
    state: { tablet },
  } = useContext(MediaQueryContext);
  const Wrapper = tablet ? AbsoluteSlideUp : FadeIn;

  return (
    <div className={"rcsaModal"}>
      <Wrapper>
        <div
          style={{ height: global.window?.innerHeight ?? 0 }}
          onClick={onClose}
          className={"hideOnDesktop w-full"}
        />
        {noContentWrapper ? (
          children
        ) : (
          <div className={clsx("rcsaModalContent", contentClassName)}>
            {children}
          </div>
        )}
      </Wrapper>
    </div>
  );
};

export { RCSAModal };
