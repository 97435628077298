import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Image from "next/image";
import styles from "styles/Cart.module.scss";
import clsx from "clsx";
import { useMoralis } from "react-moralis";
import { EmptyCart } from "./EmptyCart";
import { CartContext } from "../context/cart/context";
import {
  clearCartItems,
  setExpandingStatus,
  setSlidingIn,
} from "../context/cart/actions";
import { ExpandingStatus } from "../context/cart/state";
import Spacer from "./Spacer";
import { NumberSize, Resizable } from "re-resizable";
import { useSpring } from "@react-spring/core";
import { animated, easings } from "react-spring";
import { useTranslation } from "react-i18next";
import { RequireConnectWalletButton } from "./RequireConnectWalletButton";
import { NotificationContext } from "../context/notifications/context";
import {
  NotificationType,
  showNotification,
} from "../context/notifications/actions";
import { useRouter } from "next/router";

import { Loading } from "./Loading";
import { CartLineItem } from "./CartLineItem";
import { GiftAddressModal } from "./GiftAddressModal";
import { MintingState } from "./MintingState";
import { useMint } from "../utils/mint";
import { useSameNetwork, useWindowSize } from "../utils/hooks";
import { getChainNameByKey } from "../utils/networks";
import { friendlyErrorMessages } from "../utils/utils";
import ReactTooltip from "react-tooltip";
import { GuideTooltip } from "./GuideTooltip";
import { clearSavedCartIds, hasMintedNFT } from "../utils/cart";
import { MINT_FEE_NUMBER } from "../utils/constants";
import { MediaQueryContext } from "../context/mediaQuery/context";

interface Props {
  className?: string;
}

const Cart: React.FC<Props> = ({ className }) => {
  const cart = useContext(CartContext);
  const notification = useContext(NotificationContext);
  const resizableRef = useRef<Resizable>(null);
  const [resized, setResized] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [numberSize, setNumberSize] = useState<NumberSize>();
  const [checkedToGift, setCheckedToGift] = useState(false);
  const [giftAddress, setGiftAddress] = useState("");
  const [showGiftModal, setShowGiftModal] = useState(false);
  const { account } = useMoralis();
  const {
    state: { tablet },
  } = useContext(MediaQueryContext);

  const closed = cart.state.expandingStatus === ExpandingStatus.close;

  const onChangeExpandingStatus = useCallback(() => {
    const nextStatus = closed ? ExpandingStatus.small : ExpandingStatus.close;
    cart.dispatch(setExpandingStatus(nextStatus));

    if (nextStatus === ExpandingStatus.close) {
      setResized(false);
    }
  }, [cart, closed]);
  const closable = resized && !closed;

  const [slideInAnimatedStyles, slideInAPI] = useSpring(() => ({}));
  const [slideOutAnimatedStyles, slideOutAPI] = useSpring(() => ({}));

  const [closing, setClosing] = useState(false);

  const windowSize = useWindowSize();
  const minWidth = Math.max(423, (windowSize.width ?? 0) * 0.25);
  const slideIn = useCallback(() => {
    onChangeExpandingStatus();
    slideInAPI.start({
      from: { right: -minWidth },
      to: { right: 0 },
      config: { duration: 450, easing: easings.easeOutQuart },
    });
  }, [minWidth, slideInAPI, onChangeExpandingStatus]);

  const slideOut = useCallback(() => {
    setClosing(true);
    slideOutAPI.start({
      from: { right: 0 },
      to: { right: -(resizableRef.current?.size?.width ?? minWidth) },
      config: { duration: 450, easing: easings.easeOutQuart },
      onRest: {
        right: async () => {
          setClosing(false);
          onChangeExpandingStatus();
        },
      },
    });
  }, [minWidth, slideOutAPI, onChangeExpandingStatus]);

  useEffect(() => {
    if (closed && cart.state.slidingPanelIn) {
      slideIn();
    }

    if (cart.state.slidingPanelIn) {
      cart.dispatch(setSlidingIn(false));
    }
  }, [slideIn, cart.state.slidingPanelIn, closed, cart]);

  const { t } = useTranslation();
  const confirmationCount: string =
    process.env.NEXT_PUBLIC_REQUIRED_CONFIRMATION || "1";

  const mintTo = giftAddress || (account as string);

  const router = useRouter();

  const { mint } = useMint(cart.state.items, mintTo, {
    onSuccess: async (tx: any) => {
      await tx.wait(parseInt(confirmationCount));
      await router.push(`/txn/${tx.hash}`);
      slideOut();
      clearSavedCartIds();
      cart.dispatch(clearCartItems());

      notification.dispatch(
        showNotification({
          text: t("nftsAdded"),
          type: NotificationType.success,
        })
      );

      setIsConfirming(false);
    },
    onError: (_error: Error) => {
      setIsConfirming(false);
      notification.dispatch(
        showNotification({
          text: friendlyErrorMessages(_error, t("mintError.defaultMessage")),
          type: NotificationType.error,
        })
      );
    },
  });

  const sameNetwork = useSameNetwork();
  const handleMinting = async () => {
    if (hasMintedNFT(cart.state.items)) {
      return notification.dispatch(
        showNotification({
          text: t("cart.hasMintedNFT", {
            chainName: getChainNameByKey(process.env.NEXT_PUBLIC_CHAIN_ID!),
          }),
          type: NotificationType.error,
        })
      );
    }

    if (!sameNetwork) {
      return notification.dispatch(
        showNotification({
          text: t("wrongNetwork", {
            chainName: getChainNameByKey(process.env.NEXT_PUBLIC_CHAIN_ID!),
          }),
          type: NotificationType.error,
        })
      );
    }

    setIsConfirming(true);
    await mint();
  };

  const [alreadyExpandedCart, setAlreadyExpandedCart] = useState(false);

  useEffect(() => {
    setAlreadyExpandedCart(localStorage.getItem("expanded_cart") === "true");
  }, []);

  useEffect(() => {
    if (alreadyExpandedCart) return;

    if (resized) {
      setAlreadyExpandedCart(true);
      localStorage.setItem("expanded_cart", "true");
    }
  }, [alreadyExpandedCart, resized]);

  const cartItemsRef = useRef<HTMLDivElement>(null);

  const [lastNumberOfItems, setLastNumberOfItems] = useState<number | null>(
    null
  );
  useEffect(() => {
    if (cart.state.items.length === 0) return;

    if (
      lastNumberOfItems != null &&
      lastNumberOfItems !== cart.state.items.length &&
      cart.state.items.length > lastNumberOfItems
    ) {
      cartItemsRef.current?.scroll({
        top: cartItemsRef.current?.scrollHeight ?? 0,
        behavior: "smooth",
      });
    }

    setLastNumberOfItems(cart.state.items.length);
  }, [cart.state.items.length, lastNumberOfItems]);

  return (
    <>
      <animated.div
        className={clsx(styles.panel, className)}
        style={
          !closed
            ? closing
              ? slideOutAnimatedStyles
              : slideInAnimatedStyles
            : {}
        }
      >
        <div
          className={clsx("cursor-pointer hideOnTablet", styles.chevronButton)}
          onClick={() => {
            if (closed) {
              slideIn();
            } else {
              slideOut();
            }
          }}
          draggable={false}
        >
          <div
            className={clsx(styles.chevron, { [styles.expanding]: closable })}
            draggable={false}
          >
            <Image
              priority
              draggable={false}
              src={!closed && !closable ? "/resize.svg" : "/chevrons-right.svg"}
              width={21.07}
              height={21.07}
              alt={"Chevron arrow"}
              layout={"fixed"}
            />
          </div>
        </div>
        {cart.state.expandingStatus === ExpandingStatus.small && (
          <>
            {!tablet && !alreadyExpandedCart && !resized && (
              <div className={styles.guideTooltip}>
                <GuideTooltip
                  hide={false}
                  background={"#E6B022"}
                  color={"#073937"}
                  text={t("cart.dragLeftToExpandCart")}
                  tooltipWidth={195}
                  staticTooltip
                />
              </div>
            )}
            <Resizable
              ref={resizableRef}
              className={clsx("column", styles.smallPanel)}
              defaultSize={{
                width: tablet ? "100vw" : minWidth,
                height: "100%",
              }}
              minWidth={tablet ? "100vw" : minWidth}
              maxWidth={tablet ? "100vw" : "97vw"}
              onResize={(e, direction, elementRef, delta) => {
                setResized(delta.width > 0);
                setNumberSize(delta);
              }}
              enable={{
                top: false,
                right: false,
                bottom: false,
                left: true,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
              }}
              handleClasses={{ left: styles.leftResize }}
            >
              {!isConfirming && cart.state.items.length > 0 && (
                <div
                  className={clsx(
                    "hideOnDesktop row ai-center",
                    styles.cartHeader
                  )}
                >
                  <div className={styles.cartText}>{t("cart.text")}</div>
                  <Spacer flex={1} />
                  <div className={"cursor-pointer"} onClick={() => slideOut()}>
                    <Image
                      priority
                      src={"/close.svg"}
                      width={24}
                      height={24}
                      alt={"close"}
                      layout={"fixed"}
                      className={"pointer-events-none"}
                    />
                  </div>
                </div>
              )}
              {!isConfirming && cart.state.items.length === 0 && (
                <EmptyCart onClose={slideOut} />
              )}
              {cart.state.items.length > 0 && (
                <>
                  {isConfirming && <MintingState />}
                  {!isConfirming && (
                    <>
                      <div className={styles.cartItems} ref={cartItemsRef}>
                        {cart.state.items.map((item, index) => (
                          <React.Fragment key={item.id}>
                            {index > 0 && <div className={styles.separator} />}
                            <CartLineItem item={item} numberSize={numberSize} />
                            {cart.state.items.length === 1 &&
                              cart.state.items[0].nft && (
                                <>
                                  <div className={styles.separator} />
                                  <div
                                    className={clsx(
                                      "row ai-center",
                                      styles.messageToEnterAnotherUrl
                                    )}
                                  >
                                    <Image
                                      priority
                                      src={"/warning-white.svg"}
                                      width={14}
                                      height={14}
                                      alt={"message to enter another url"}
                                    />
                                    <Spacer width={8} />
                                    <div>{t("cart.enterAnotherUrl")}</div>
                                  </div>
                                </>
                              )}
                          </React.Fragment>
                        ))}
                      </div>
                      <Spacer flex={1} />
                      <div
                        className={styles.checkedToGiftContainer}
                        onClick={() => {
                          const nextValue = !checkedToGift;
                          if (nextValue) {
                            setShowGiftModal(true);
                          } else {
                            setGiftAddress("");
                          }
                          setCheckedToGift(nextValue);
                        }}
                      >
                        <Image
                          priority
                          src={
                            checkedToGift
                              ? "/checked-square.svg"
                              : "/check-square.svg"
                          }
                          width={21.06}
                          height={21.06}
                          alt={"check to gift"}
                        />
                        <Spacer width={7.02} />
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              checkedToGift && giftAddress
                                ? t("cart.giftTo", {
                                    contractAddress: giftAddress,
                                  })
                                : t("cart.checkedToGift"),
                          }}
                        />
                      </div>
                      <div className={styles.separator} />
                    </>
                  )}
                  <div className={clsx("row ai-center", styles.footer)}>
                    <div>
                      <div className={styles.total}>
                        {t("cart.total")} ({cart.state.items.length})
                      </div>
                      <div className={"row ai-center"}>
                        <div className={styles.amount}>
                          {cart.state.items.length * MINT_FEE_NUMBER} ETH
                        </div>
                        <Spacer width={7.07} />
                        <div
                          className={styles.gasFeeIcon}
                          data-tip={t("cart.gasFeeInfo", {
                            fee: MINT_FEE_NUMBER,
                          })}
                        >
                          <Image
                            priority
                            src={"/warning-white.svg"}
                            width={14}
                            height={14}
                            alt={"gas fee icon info"}
                          />
                        </div>
                        <ReactTooltip
                          place={"top"}
                          effect={"solid"}
                          arrowColor={"#F7F3EA"}
                          className={styles.tooltip}
                        />
                      </div>
                    </div>
                    <Spacer flex={1} />
                    <RequireConnectWalletButton
                      disabled={isConfirming}
                      onClick={handleMinting}
                    >
                      <button
                        className={clsx(
                          styles.mintButton,
                          "cursor-pointer row ai-center"
                        )}
                        disabled={isConfirming}
                      >
                        {isConfirming && (
                          <>
                            <Loading size={22} />
                            <Spacer width={8} />
                          </>
                        )}
                        <div className={styles.mintButtonText}>
                          {t("cart.mintButton")}
                        </div>
                      </button>
                    </RequireConnectWalletButton>
                  </div>
                </>
              )}
            </Resizable>
          </>
        )}
      </animated.div>
      <GiftAddressModal
        show={showGiftModal}
        onClose={() => {
          setCheckedToGift(false);
          setShowGiftModal(false);
          setGiftAddress("");
        }}
        onSetGiftAddress={(address) => {
          setCheckedToGift(true);
          setShowGiftModal(false);
          setGiftAddress(address);
        }}
      />
    </>
  );
};

export { Cart };
