export enum ActionType {
  ShowConnectWalletModal,
}

export interface ShowConnectWalletModal {
  type: ActionType.ShowConnectWalletModal;
  payload: boolean;
}

export type WalletActions = ShowConnectWalletModal;

export const showConnectWalletModal = (
  show: boolean
): ShowConnectWalletModal => ({
  type: ActionType.ShowConnectWalletModal,
  payload: show,
});
