import { useCallback, useEffect, useRef, useState } from "react";
import { useChain } from "react-moralis";

export const useIsMounted = () => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  return mounted;
};

export const useSameNetwork = (): boolean => {
  const { chainId } = useChain();

  return chainId === process.env.NEXT_PUBLIC_CHAIN_ID;
};

// ref: https://usehooks.com/useWindowSize/
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<{
    width?: number;
    height?: number;
  }>({
    width: global.window?.innerWidth,
    height: global.window?.innerHeight,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export const useScrolled = () => {
  const [scrolled, setScrolled] = useState(false);
  const onScroll = useCallback(
    () => setScrolled(global.window.scrollY > 4),
    []
  );
  useEffect(() => {
    setScrolled(global.window.scrollY > 0);
    global.window.removeEventListener("scroll", onScroll);
    global.window.addEventListener("scroll", onScroll);

    return () => global.window.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  return scrolled;
};

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => void (ref.current = value), [value]);
  return ref.current;
}
