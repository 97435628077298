import "styles/globals.scss";
import "styles/variables.css";
import { MoralisProvider } from "react-moralis";
import { AppProps } from "next/app";
import { CartContextProvider } from "../context/cart/context";
import { combineComponents } from "../utils/combineComponents";
import "utils/firebase";
import Notifications from "../components/Notifications";
import { NotificationContextProvider } from "../context/notifications/context";
import { WalletContextProvider } from "../context/wallet/context";
import { ConnectWalletModal } from "../components/ConnectWalletModal";
import { withPasswordProtect } from "@storyofams/next-password-protect";
import React, { useContext, useEffect } from "react";
import "/i18n";
import { SocialNotifications } from "../components/SocialNotifications";
// @ts-ignore
import { IKContext } from "imagekitio-react";
import { install } from "resize-observer";
import ProgressBar from "@badrap/bar-of-progress";
import { Router } from "next/router";
import { Cart } from "../components/Cart";
import {
  ConfirmationModalContext,
  ConfirmationModalContextProvider,
} from "../context/confirmationModal/context";
import { RCSAModal } from "../components/RCSAModal";
import { MediaQueryContextProvider } from "../context/mediaQuery/context";

if (typeof window !== "undefined") {
  install();
}

// Pade loading indicator
const progress = new ProgressBar({
  color: "#073937",
});
Router.events.on("routeChangeStart", progress.start);
Router.events.on("routeChangeComplete", progress.finish);
Router.events.on("routeChangeError", progress.finish);

const providers = [
  MediaQueryContextProvider,
  NotificationContextProvider,
  CartContextProvider,
  WalletContextProvider,
  ConfirmationModalContextProvider,
];

const APP_ID = process.env.NEXT_PUBLIC_MORALIS_APPLICATION_ID ?? "";
const SERVER_URL = process.env.NEXT_PUBLIC_MORALIS_SERVER_URL ?? "";

const ContextProviders = combineComponents(...providers);

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    const appHeight = () => {
      document.documentElement.style.setProperty(
        "--app-height",
        `${window.innerHeight}px`
      );
    };
    window.addEventListener("resize", appHeight);
    appHeight();

    return () => {
      window.removeEventListener("resize", appHeight);
    };
  }, []);

  return (
    <IKContext urlEndpoint={process.env.NEXT_PUBLIC_IMAGEKIT_URL}>
      <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
        <ContextProviders>
          <Component {...pageProps} />
          <Notifications />
          <ConnectWalletModal />
          <SocialNotifications />
          <Cart className={"cartShadow"} />
          <ConfirmationModalComp />
        </ContextProviders>
      </MoralisProvider>
    </IKContext>
  );
}

const ConfirmationModalComp: React.FC = () => {
  const confirmationModal = useContext(ConfirmationModalContext);

  if (confirmationModal.state.children) {
    return (
      <RCSAModal noContentWrapper>{confirmationModal.state.children}</RCSAModal>
    );
  }

  return null;
};

export default process.env.ENABLED_PASSWORD_PROTECT
  ? withPasswordProtect(MyApp, {
      loginApiUrl: "/api/loginHandler",
      checkApiUrl: "/api/passwordCheckHandler",
    })
  : MyApp;
