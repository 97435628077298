import { NotificationType } from "./actions";

export interface NotificationState {
  type?: NotificationType;
  text?: string;
  time?: number;
  data?: any;
}

export const initialState: NotificationState = {
  // time: new Date().getTime(),
  // text: "Ooops! We couldn't find that NFTOoops! We couldn't find that NFTOoops! We couldn't find that NFTOoops! We couldn't find that NFTOoops! We couldn't find that NFT",
};
