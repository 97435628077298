import { NotificationState } from "./state";

export enum NotificationType {
  error,
  success,
  socialMinted,
  errorCloseManually,
}

export enum ActionType {
  ShowNotification,
}

export interface ShowNotification {
  type: ActionType.ShowNotification;
  payload: NotificationState;
}

export type NotificationActions = ShowNotification;

export const showNotification = ({
  text,
  type,
  data,
}: {
  text: string;
  type: NotificationType;
  data?: any;
}): ShowNotification => ({
  type: ActionType.ShowNotification,
  payload: {
    type,
    text,
    time: Date.now(),
    data,
  },
});
