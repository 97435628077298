import React, { useCallback, useEffect, useRef, useState } from "react";
import { NFTRCSA } from "./NFTRCSA";
import { RCSANFT } from "../utils/types";
import { delay } from "../utils/utils";
import html2canvas from "html2canvas";
import { svgString2Image } from "../utils/imageKit";

interface Props {
  data: RCSANFT;
  onGenerated(dataUrl: string): void;
  onDone(): void;
  showXNFT?: boolean;
}

const GenerateNFTImage: React.FC<Props> = ({
  data,
  onGenerated,
  onDone,
  showXNFT = false,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [generatingImage, setGeneratingImage] = useState(false);
  const [mediaLoaded, setMediaLoaded] = useState(false);
  const [qrLoaded, setQRLoaded] = useState(false);

  const generateImage = useCallback(async () => {
    if (generatingImage || !mediaLoaded || !qrLoaded) return;

    setGeneratingImage(true);
    try {
      if (containerRef.current) {
        const img = document.getElementById(
          `nft_img_${data.tokenId}`
        ) as HTMLImageElement;
        if (img) {
          if (img.src.startsWith("http")) {
            img.src = await fetch(`/api/imageToBase64?url=${img.src}`)
              .then((r) => r.json())
              .then((j) => j.data);

            await delay(0);
          }

          if (img.src.startsWith("data:image/svg")) {
            img.src = await svgString2Image(img.src, 400, 400, "png");
            await delay(0);
          }
        }

        const canvas = await html2canvas(containerRef.current, {
          allowTaint: true,
          useCORS: true,
          backgroundColor: "transparent",
          scale: 1,
        });
        const dataUrl = canvas.toDataURL("image/png");
        canvas.remove();
        onGenerated(dataUrl);
      }
    } catch (e) {
      console.log("generateImage", e);
    } finally {
      onDone();
      setGeneratingImage(false);
    }
  }, [onDone, data, onGenerated, generatingImage, mediaLoaded, qrLoaded]);

  useEffect(() => {
    generateImage().then();
  }, [generateImage]);

  return (
    <div
      ref={containerRef}
      style={{
        position: "fixed",
        left: "100vw",
        width: 1286,
        height: 650,
        userSelect: "none",
      }}
    >
      <NFTRCSA
        item={{ nft: data, id: data.tokenId?.toString() }}
        onMediaLoaded={() => setMediaLoaded(true)}
        onQRLoaded={() => setQRLoaded(true)}
        useImageIfVideo
        useOptimisedSrc={false}
        imgId={`nft_img_${data.tokenId}`}
        lazyLoadImage={false}
        forceToUseQRURL
        showXNFT={showXNFT}
      />
    </div>
  );
};

export { GenerateNFTImage };
