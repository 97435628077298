import React, { useCallback, useContext, useEffect, useReducer } from "react";
import styles from "styles/Notifications.module.scss";
import Spacer from "./Spacer";
import { NotificationContext } from "../context/notifications/context";
import { NotificationItemType, RCSANFT } from "../utils/types";
import { animated } from "@react-spring/web";
import { easings, useTransition } from "react-spring";
import clsx from "clsx";
import Image from "next/image";
import { NotificationType } from "../context/notifications/actions";
import { NFTMedia } from "./NFTMedia";
import { toNFTMediaItem } from "../utils/rcsNft";
import { getTheme } from "../utils/theme";

const Notifications: React.FC = () => {
  const notifications = useContext(NotificationContext);
  const { time, text, type, data } = notifications.state;
  const [items, localDispatch] = useReducer(
    (
      state: NotificationItemType[],
      action: {
        type: "add" | "reset" | "remove";
        payload?: NotificationItemType;
      }
    ): NotificationItemType[] => {
      switch (action.type) {
        case "add": {
          if (action.payload != null) {
            if (
              state.findIndex((item) => item.time === action.payload?.time) !==
              -1
            ) {
              return state;
            }

            return [action.payload, ...state];
          }

          return state;
        }

        case "remove": {
          return state.filter((item) => item.time !== action.payload?.time);
        }

        default:
          return state;
      }
    },
    []
  );

  useEffect(() => {
    if (text != null && time != null && type != null) {
      localDispatch({ type: "add", payload: { time, text, type, data } });
    }
  }, [time, text, type, data]);

  const onRemove = useCallback(
    (item: NotificationItemType) =>
      localDispatch({ type: "remove", payload: item }),
    []
  );

  const transitions = useTransition(items, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      easing: easings.linear,
      duration: 500,
    },
  });

  return (
    <div className={styles.container}>
      {transitions((style, item) => (
        <React.Fragment key={item.time}>
          <animated.div
            style={style}
            className={styles.notificationItemContainer}
          >
            {item.type !== NotificationType.socialMinted && (
              <NotificationItem data={item} onClose={() => onRemove(item)} />
            )}
            {item.type === NotificationType.socialMinted && (
              <NotificationMintedItem
                data={item}
                onClose={() => onRemove(item)}
              />
            )}
          </animated.div>
          <Spacer height={8} />
        </React.Fragment>
      ))}
    </div>
  );
};

const NotificationItem: React.FC<{
  data: NotificationItemType;
  onClose(): void;
}> = ({ data, onClose }) => {
  useEffect(() => {
    setTimeout(() => onClose(), 2500);
  }, [onClose]);

  return (
    <div
      className={clsx("row", styles.notification, {
        [styles.notificationSuccess]: data.type === NotificationType.success,
      })}
    >
      <div
        className={styles.text}
        dangerouslySetInnerHTML={{
          __html: data.text,
        }}
      />
      <Spacer flex={1} />
      <div
        className={clsx("cursor-pointer", styles.closeButton)}
        onClick={onClose}
      >
        <Image
          priority
          src={"/close.svg"}
          width={24}
          height={24}
          alt={"close"}
          layout={"fixed"}
        />
      </div>
    </div>
  );
};

const NotificationMintedItem: React.FC<{
  data: NotificationItemType;
  onClose(): void;
}> = ({ data, onClose }) => {
  const nft = data.data! as RCSANFT;

  useEffect(() => {
    setTimeout(() => onClose(), 15000);
  }, [onClose]);

  return (
    <div
      className={clsx(
        "row",
        styles.notification,
        styles.notificationSocialMinted
      )}
    >
      <div
        style={{
          background: getTheme(nft.theme).background,
          borderRadius: 2,
          padding: "4px 17px",
        }}
        className={"column ai-center jc-center"}
      >
        <NFTMedia item={toNFTMediaItem(nft)} width={30.78} />
      </div>
      <Spacer width={16} />
      <div
        className={styles.text}
        dangerouslySetInnerHTML={{
          __html: data.text,
        }}
      />
      <Spacer flex={1} />
      <div
        className={clsx("cursor-pointer", styles.closeButton)}
        onClick={onClose}
      >
        <Image
          priority
          src={"/close.svg"}
          width={24}
          height={24}
          alt={"close"}
          layout={"fixed"}
        />
      </div>
    </div>
  );
};

export default Notifications;
